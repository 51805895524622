<template>
<div class="container">
    <div class="info-container">
        <div class="info-title">
            <span v-if='auth("ADMIN")'>{{schoolName}}:</span>
            <span>{{classDetail.name}}</span>
        </div>
        <div class="info-sub">
            <div class="info-sub-item">
                <span class="key">教学计划: </span>
                <span class="value" :style="classDetail.planName !== '' ? 'cursor: pointer;' : ''"
                    @click='routerChange(1)'>{{classDetail.planName || '无'}}</span>
                <el-tooltip content="设置班级教学计划" placement="top" v-if="auth('SCHOOL') && auth('class.update')">
                    <el-button class="set-plan-btn" type="text" icon="iconfont icon-edit" @click="handleDialogShow" />
                </el-tooltip>
            </div>
            <div class="info-sub-item">
                <span class="key">创建时间: </span>
                <span class="value">{{ classDetail.create_time | timeFilter }}</span>
            </div>
            <div class="info-sub-item">
            </div>
        </div>
    </div>
    <div class="info">
        <div class="school-class-tabs">
            <el-tabs class="my-tabs class-tabs" v-model="activeTab">
                <el-tab-pane label="教师列表" name="teacher">
                    <class-teacher :school-id="schoolId" :class-id='classId'/>
                </el-tab-pane>
                <el-tab-pane label="学生列表" name="student">
                    <class-student :school-id="schoolId" :class-id='classId' :org-id="orgId"/>
                </el-tab-pane>
                <el-tab-pane label="设备监控" name="device" v-if='!auth("PROVIDER")'>
                    <DeviceManagement :school-id="schoolId" :class-id='classId' :org-id="orgId"/>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
    <el-dialog class="school-classDetail-dialog" title="设置教学计划"
        :visible.sync="dialogVisible" :before-close="handleDialogClose">
        <div class="school-classDetail-dialog-content">
            <span class="key">教学计划</span>
            <el-select class="item" placeholder="请选择班级教学计划" v-model="classDetail.planId">
                <el-option v-for="item of planList" :label="item.name" :value="item.id" :key="item.id" />
            </el-select>
        </div>
        <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
            <el-button type="primary" @click="handleSetPlan">保存</el-button>
            <el-button @click="handleDialogClose">取消</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
import Core from '@/core';

export default {
    components: {
        ClassTeacher: () => import('./components/ClassTeacher'),
        ClassStudent: () => import('./components/ClassStudent'),
        DeviceManagement: () => import('./components/DeviceManagement'),
    },
    data() {
        return {
            activeTab: 'teacher',

            orgId: 0,
            schoolId: 0,
            schoolName: '',

            classId: 0,
            classDetail: {
                name: '',
                create_time: '',
                planId: '',
                planName: '',
            },

            dialogVisible: false,
            planList: [],
        };
    },
    created() {
        this.orgId = this.$route.query.org_id || 0;
        this.schoolId = this.$route.query.school_id;
        this.schoolName = this.$route.query.school_name;
        this.classId = this.$route.query.class_id;

        if (!this.auth('ADMIN')) {
            this.getClassPlan();
        }

        this.getClassDetail();
    },
    methods: {
        auth: Core.Utils.auth,
        getClassPlan(){ // 获取排课信息
            Core.Api.Plan.list(
                0,
                100,
                '',
                '',
                2,
                this.schoolId
            ).then((res) => {
                this.planList = res.list
                if (this.classDetail.planName === '') {
                    for (let i = 0; i < this.planList.length; i++) {
                        if (this.planList[i].id === this.classDetail.planId) {
                            this.classDetail.planName = this.planList[i].name
                        }
                    }
                }
            });
        },
        getClassDetail() { // 获取班级详情
            Core.Api.SchoolClass.detail(this.classId).then((res) => {
                this.classDetail.name = res.detail.name
                this.classDetail.create_time = res.detail.create_time
                if (res.detail.plan_id !== 0) {
                    this.classDetail.planId = res.detail.plan_id
                    for (let i = 0; i < this.planList.length; i++) {
                        if (this.planList[i].id === this.classDetail.planId) {
                            this.classDetail.planName = this.planList[i].name
                        }
                    }
                } else {
                    this.classDetail.planId = ''
                    this.classDetail.planName = ''
                }
            });
        },

        handleDialogShow() { // 显示弹框
            this.dialogVisible = true
        },
        handleDialogClose() { // 非'确定'关闭弹框
            this.dialogVisible = false
            this.getClassDetail()
        },
        handleSetPlan() {  // '确定'关闭弹框 设置排课信息
            this.dialogVisible = false
            Core.Api.SchoolClass.setPlan(
                this.classId,
                this.classDetail.planId
            ).then((res) => {
                this.$message.success('设置成功')
                this.getClassDetail()
            })
        },
        routerChange(key, q = '') {
            switch (key) {
                case 1:  // 排课
                    this.$router.push({
                        path: '/plan/plan-detail',
                        query: {
                            plan_id: this.classDetail.planId,
                            plan_type: 2,
                            can_edit: this.auth('SCHOOL') ? true : false
                        }
                    });
                    break;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .info-container {
        width: 100%;
        background: #ffffff;
        box-sizing: border-box;
        padding: 0 30px;
        border-bottom: 0;
        .info-title {
            padding-top: 20px;
            font-size:20px;
            font-weight:500;
            color:$color-black;
            line-height:28px;
        }
        .info-sub {
            @include flex(row, space-between, center);
            flex-wrap: wrap;
            padding-top: 30px;
            padding-bottom: 20px;
            border-bottom: 1px solid $border-color;
            &-item{
                width: 300px;
                span{
                    font-size: $font-size-base;
                    font-weight: 400;
                    line-height: 20px;
                }
                .key {
                    color:$color-black-light;
                }
                .value {
                    color:$color-black;
                }
                .set-plan-btn {
                    font-size: 14px;
                    color: $color-black;
                    padding: 0 0 0 4px;
                    position: relative;
                    top: 1px;
                    &:hover {
                        color: $color-primary;
                    }
                }
            }
        }
    }
    .info {
        position: relative;
        .tabs {
            width: 100%;
            box-sizing: border-box;
        }
        .btns{
            position: absolute;
            top: 7px;
            right: 20px;
        }
    }
    .school-classDetail-dialog{
        &-content{
            display: flex;
            align-items: center;
            width: 400px;
            margin: 0 auto;
            .key {
                display: block;
                width: 80px;
                text-align: center;
                font-size: 16px;
            }
            .item{
                width: calc(100% - 80px);
            }
        }
    }
}
</style>
<style lang="scss">
.info .school-class-tabs {
    .class-tabs {
        .el-tabs__header {
            background-color: #fff;
            .el-tabs__nav-scroll {
                padding: 0 20px;
            }
        }
    }
}
.school-classDetail-dialog {
    .el-dialog{
        width: 500px;
        border-radius: 5px;
        overflow: hidden;
        .el-dialog__header,.el-dialog__footer{
            background:rgba(248,249,250,1);
        }
        .el-dialog__header{
            border-bottom:1px solid rgba(234,237,240,1);
        }
        .el-dialog__footer{
            padding: 10px;
            border-top:1px solid rgba(234,237,240,1);
        }
    }
}
</style>
